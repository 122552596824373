import { render, staticRenderFns } from "./Well.vue?vue&type=template&id=45fc136d&"
import script from "./Well.vue?vue&type=script&lang=ts&"
export * from "./Well.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_react-dom@18_vyrjir3tafa6d7xmeapelwsujq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports