import { makeInjectionKey } from "@axisvue/utils";
import { inject, provide, ref } from "vue";
import RepositoryFactory from "@/api/repositoryFactory";
const AlertsRepository = RepositoryFactory.alerts;
export function withAlerts(experimentId) {
    const alert = ref({});
    const showAlert = ref(true);
    const fetchAlerts = async () => {
        try {
            const { data } = await AlertsRepository.getAlerts(experimentId);
            alert.value = data;
        }
        catch (error) {
            throw new Error("Alerts could not be loaded\nTry again");
        }
    };
    const toggleAlertOnOff = () => {
        showAlert.value = !showAlert.value;
    };
    const createAlert = async (alert) => {
        try {
            await AlertsRepository.createAlert(experimentId, alert);
        }
        catch (error) {
            throw new Error("Alert could not be created\nTry again");
        }
    };
    const removeAlert = async () => {
        try {
            await AlertsRepository.removeAlert(experimentId);
        }
        catch (error) {
            throw new Error("Alert could not be removed\nTry again");
        }
    };
    return {
        alert,
        fetchAlerts,
        createAlert,
        removeAlert,
        showAlert,
        toggleAlertOnOff
    };
}
export const AlertsInjectionKey = makeInjectionKey();
/**
 * Provides `Alerts` to all descendants of a component.
 *
 * Use in conjunction with `useAlerts`.
 */
export function provideAlerts(Alerts) {
    provide(AlertsInjectionKey, Alerts);
}
/**
 * Injects `Alerts` into a component.
 *
 * Make sure a parent component provides the right state
 * by using `provideAlerts`, otherwise an error will
 * be thrown
 */
export function useAlerts() {
    const Alerts = inject(AlertsInjectionKey);
    if (Alerts === undefined)
        throw new Error("Alerts were not provided.");
    return Alerts;
}
