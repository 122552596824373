import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useVirtualWellplate } from "../_providers/VirtualWellPlateShapeProvider/withVirtualWellplate";
import { useSelect } from "@/pages/wellPlate/_providers/withSelect/withSelect";
import AreaSelector from "../Mapview/shapes/AreaSelector.vue";
import ROI from "../Mapview/shapes/ROI.vue";
import Well from "../Mapview/shapes/Well.vue";
import VirtualWellPlate from "./VirtualWellPlate.vue";
import { useExperiment } from "../_providers/withExperiment";
import { useSliceProperties } from "../_providers/withSliceProperties";
import { useAreas } from "../_providers/withAreas";
import { useConfluence } from "../_providers/withConfluence";
export default defineComponent({
    components: {
        VirtualWellPlate,
        Well,
        ROI,
        AreaSelector
    },
    props: {
        wellplateDetails: {
            type: Object,
            required: true
        },
        areas: {
            type: Array,
            required: true
        },
        areasData: {
            type: Object,
            required: true
        },
        isBrightfield: {
            type: Boolean,
            default: false
        },
        dragSelect: {
            type: Boolean,
            default: true
        },
        customOptions: {
            type: Object,
            default: () => ({}),
            required: false
        },
        enabledGroupedAreasInteraction: {
            type: Boolean,
            required: false,
            default: true
        },
        autoSelectOnInitEnabled: {
            type: Boolean,
            required: false,
            default: true
        },
        ignoreGroups: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props) {
        const virtualWellPlateProvider = useVirtualWellplate();
        const { openLuxExperiment } = useExperiment();
        const { isOmniNewCamera } = useSliceProperties();
        const confluenceProvider = useConfluence();
        console.log(confluenceProvider.confluence.value);
        const selector = useSelect();
        const { areaNames } = useAreas();
        const windowWidth = ref(window.innerWidth);
        const drag = ref(false);
        const areaSelection = ref(props.dragSelect);
        const allROIs = computed(() => virtualWellPlateProvider.getAllROIs());
        const getZoomLevel = () => {
            if (windowWidth.value <= 1600) {
                if (!isOmniNewCamera.value) {
                    return 1;
                }
                else
                    return 0.5;
            }
            else {
                if (!isOmniNewCamera.value)
                    return 1;
                else
                    return 0.5;
            }
        };
        // Different zoom levels to fit the whole wellplate in the map
        const zoomLevelFluo = computed(() => {
            return windowWidth.value <= 1400
                ? 1.9
                : windowWidth.value <= 1600
                    ? 2.2
                    : 2.4;
        });
        const options = computed(() => {
            var _a, _b, _c, _d, _e, _f;
            if (props.isBrightfield) {
                return {
                    mode: "brightfield",
                    initialZoom: (_a = props.customOptions.initialZoom) !== null && _a !== void 0 ? _a : getZoomLevel(),
                    dragging: false,
                    zoomControl: false,
                    scrollWheelZoom: false,
                    wellOpacity: 0.5,
                    minZoom: (_b = props.customOptions.initialZoom) !== null && _b !== void 0 ? _b : getZoomLevel(),
                    maxZoom: (_c = props.customOptions.initialZoom) !== null && _c !== void 0 ? _c : 1.5,
                    center: [-30000, 46000]
                };
            }
            return {
                mode: "fluorescence",
                initialZoom: (_d = props.customOptions.initialZoom) !== null && _d !== void 0 ? _d : zoomLevelFluo.value,
                minZoom: (_e = props.customOptions.initialZoom) !== null && _e !== void 0 ? _e : zoomLevelFluo.value,
                maxZoom: (_f = props.customOptions.initialZoom) !== null && _f !== void 0 ? _f : 5,
                wellWeight: 1,
                wellOpacity: 0.7,
                dragging: false,
                zoomControl: true,
                scrollWheelZoom: true,
                center: [45, 65]
            };
        });
        const onResize = () => {
            windowWidth.value = window.innerWidth;
        };
        const viewROIs = computed({
            get() {
                return virtualWellPlateProvider.getData().viewROIs;
            },
            set(newVal) {
                virtualWellPlateProvider.getData().viewROIs = newVal;
            }
        });
        const handleDrag = () => {
            drag.value = !drag.value;
            areaSelection.value = !areaSelection.value;
        };
        const onMouseUp = () => {
            areaSelection.value = false;
            // causes the area to be selected while remaining in the selection mode for user
            setTimeout(() => {
                areaSelection.value = true;
            }, 100);
        };
        const selectAllWellPlateItems = () => {
            selector.selectItems(areaNames.value, {
                ignoreModifierKey: true
            });
        };
        // Hacky way, because I cannot override the leaflet control class without messing Mapview
        onMounted(() => {
            const control = document.querySelector(".leaflet-top.leaflet-right");
            if (control) {
                control.className += " flex";
            }
            window.addEventListener("resize", onResize);
            virtualWellPlateProvider.setPatternScanWells(props.areasData);
            if (props.autoSelectOnInitEnabled) {
                selectAllWellPlateItems();
            }
        });
        onUnmounted(() => {
            window.removeEventListener("resize", onResize);
        });
        return {
            areaSelection,
            drag,
            windowWidth,
            virtualWellPlateProvider,
            viewROIs,
            options,
            allROIs,
            openLuxExperiment,
            onMouseUp,
            handleDrag,
            confluenceProvider
        };
    }
});
